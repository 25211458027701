import {
  BUNDLE_ATTRIBUTE_TYPE,
  BUNDLE_CHECKOUT_ENABLED,
  BUNDLE_CODE_CUSTOM,
  BUNDLE_CODE_DEFAULT,
  BUNDLE_CODE_DEFAULT_COUPON_CODE,
  BUNDLE_CODE_MEALS,
  BUNDLE_CODE_MEALS_MAX,
  BUNDLE_DAILY_TAXON_CODE,
  CODE_EXTRA_MAIN_TAXON,
  CUSTOM_BUNDLE_VALUE,
  MEAL_CODE_FOOD_PREFERENCE,
  MEAL_CODE_INGREDIENT_DESCRIPTION,
  MEAL_CODE_NEW,
  MEAL_CODE_NUTRISCORE,
  MEAL_CODE_NUTRITIONAL_VALUE,
  MEAL_CODE_PREPARATION_ADVICE,
  MEAL_CODE_STORAGE_INSTRUCTIONS,
} from "@middleware/constants";
import { getLocale } from "@config/locales";
import {
  AssigningType,
  BundlePromotionDetails,
  CHANNEL_CODES,
  DiscountType,
  DiscountTypeValue,
  EDevice,
  ERuleType,
  ERuleTypeValue,
  IConfigTab,
  ICustomer,
  IMenuResponse,
  IProduct,
  IProductResponse,
  IProductTaxon,
  IPromotionCouponActionResponse,
  IPromotionCouponAssigningActionResponse,
  IPromotionCouponDiscountActionResponse,
  IPromotionCouponRuleResponse,
  IPromotionCouponsResponse,
} from "@middleware/types";
import { AxiosError, AxiosResponse } from "axios";
import { limitText } from "./utils";
import { getIsProspect } from "./customer";

export const getProductDescription = (product: IProduct, short = true) => {
  return short ? limitText(product.shortDescription, 130) : product.description;
};

export const serializeProduct = (bundles: IProductResponse[]): IProduct[] => {
  const mapping = {
    [BUNDLE_CHECKOUT_ENABLED]: "checkoutEnabled",
    [BUNDLE_CODE_DEFAULT]: "isDefault",
    [BUNDLE_CODE_MEALS]: "mealsNumber",
    [BUNDLE_CODE_MEALS_MAX]: "maxMealsNumber",
    [BUNDLE_CODE_DEFAULT_COUPON_CODE]: "defaultCouponCode",
    [BUNDLE_CODE_CUSTOM]: "isCustomBundle",
    [MEAL_CODE_NUTRISCORE]: "nutriscore",
    [MEAL_CODE_FOOD_PREFERENCE]: "foodPreference",
    [MEAL_CODE_INGREDIENT_DESCRIPTION]: "ingredients",
    [MEAL_CODE_NUTRITIONAL_VALUE]: "nutritional",
    [MEAL_CODE_PREPARATION_ADVICE]: "preparation",
    [MEAL_CODE_STORAGE_INSTRUCTIONS]: "storageInstructions",
    [MEAL_CODE_NEW]: "isNew",
  };
  const newBundlesFormat = bundles.map((bundle) => {
    const attributes = bundle.attributes?.reduce((accumulator, attribute) => {
      if (attribute.localeCode !== null && attribute.localeCode !== getLocale())
        return accumulator;

      if (attribute.attributeCode === BUNDLE_ATTRIBUTE_TYPE) {
        return {
          ...accumulator,
          isCustomBundle: Array.isArray(attribute.value)
            ? CUSTOM_BUNDLE_VALUE === attribute.value[0]
            : false,
        };
      }

      if (attribute.attributeCode in mapping) {
        return {
          ...accumulator,
          [mapping[attribute.attributeCode as keyof typeof mapping]]:
            attribute.value,
        };
      }

      return accumulator;
    }, {});

    const packaging = bundle.packaging ?? 1;

    return {
      id: bundle.id,
      code: bundle.code,
      codeVariant: bundle.variants[0].code,
      name: bundle.name,
      shortDescription: bundle.shortDescription,
      description: bundle.description,
      ingredients: "",
      nutritional: "",
      preparation: "",
      price: bundle.variants[0].price / packaging,
      isNew: bundle.isNew ?? false,
      priority: bundle.priority ?? 0,
      foodPreference: [],
      isDefault: false,
      checkoutEnabled: false,
      mealsNumber: 0,
      maxMealsNumber: 0,
      desktopPosition: bundle.desktopPosition ?? 1,
      mobilePosition: bundle.mobilePosition ?? 1,
      defaultCouponCode: "",
      nutriscore: [],
      storageInstructions: [],
      image: bundle.images.length > 0 ? bundle.images[0].path : "",
      weight: bundle.variants[0].weight,
      volume: bundle.variants[0].volume,
      packaging: packaging,
      isCustomBundle: false,
      productTaxons: bundle.productTaxons,
      isBest:
        attributes && "isNew" in attributes && attributes.isNew === true
          ? false
          : true,
      ...attributes,
    };
  });

  return newBundlesFormat;
};

export const isDailyBundle = (bundle: IProduct) => {
  return (
    bundle.productTaxons?.some(
      (item) =>
        item.taxon.code === BUNDLE_DAILY_TAXON_CODE ||
        item.taxon.code === CODE_EXTRA_MAIN_TAXON
    ) ?? false
  );
};

export const sortBundles = (bundles: IProduct[], ordre = "ASC"): IProduct[] => {
  return ordre === "ASC"
    ? bundles.sort((a, b) => (a.mealsNumber > b.mealsNumber ? 1 : -1))
    : bundles.sort((a, b) => (a.mealsNumber < b.mealsNumber ? 1 : -1));
};

export const sortProductByUser = (product: IProduct, isProspect: boolean) => {
  if (isProspect) {
    return product.isBest ? 1 : product.isNew ? 2 : 3;
  }

  return product.isNew ? 1 : product.isBest ? 2 : 3;
};

export const sortProductsByDevice = (products: IProduct[], device: EDevice) => {
  return device === EDevice.MOBILE
    ? products.sort((a, b) => (a.mobilePosition > b.mobilePosition ? 1 : -1))
    : products.sort((a, b) => (a.desktopPosition > b.desktopPosition ? 1 : -1));
};

export const sortProductByAttr = (
  products: IProduct[],
  device: EDevice,
  customer: ICustomer | undefined
): IProduct[] => {
  const isProspect = getIsProspect(customer);

  const newProducts = sortProductsByDevice(
    products.filter((prod) => prod.isNew),
    device
  );
  const bestProducts = sortProductsByDevice(
    products.filter((prod) => prod.isBest),
    device
  );

  if (isProspect) {
    return [...bestProducts, ...newProducts];
  }

  return [...newProducts, ...bestProducts];
};

export const sortMeals = (meals: IProduct[]): IProduct[] => {
  return meals.sort((a, b) => (a.priority > b.priority ? 1 : -1));
};

export const getProductsFromResponse = <T>(
  productResponse: AxiosResponse<T> | AxiosResponse<AxiosError>,
  order = "ASC"
): IProduct[] => {
  return sortBundles(
    serializeProduct(productResponse.data as IProductResponse[]),
    order
  ).filter((bundle) => isDailyBundle(bundle));
};

export const getProductsOfMenu = (
  productResponse: AxiosResponse | AxiosResponse<AxiosError>
): IProduct[] => {
  const menuItemsResponse = productResponse.data as IMenuResponse;
  const productsResponse: IProductResponse[] = menuItemsResponse.items.map(
    (item) => {
      return {
        ...item.product,
        priority: item.priority,
      };
    }
  );

  return serializeProduct(productsResponse);
};
export const isPromotionFirstOrder = (
  promotionRules: IPromotionCouponRuleResponse[]
) => {
  const promotionRule = promotionRules.find(
    (promotion) => promotion.type === ERuleType.RULE_FIRST_ORDER
  );
  if (promotionRule !== undefined)
    return promotionRule.configuration[ERuleTypeValue.NTH] === 1;

  return false;
};
export const getPromotionSubscriptionTypeCode = (
  promotionActions: IPromotionCouponActionResponse[]
): string | null => {
  const promotionGroupAction = promotionActions
    .filter((action): action is IPromotionCouponAssigningActionResponse =>
      Object.values(AssigningType).some((value) => value === action.type)
    )
    .find((action) => action.type === AssigningType.SUBSCRIPTION_ASSIGNER);

  if (promotionGroupAction === undefined) return null;

  return promotionGroupAction.configuration["subscription_type_code"];
};

export const getPromotionGroupCode = (
  promotionActions: IPromotionCouponActionResponse[]
): string | null => {
  const promotionGroupAction = promotionActions
    .filter((action): action is IPromotionCouponAssigningActionResponse =>
      Object.values(AssigningType).some((value) => value === action.type)
    )
    .find((action) => action.type === AssigningType.GROUP_ASSIGNER);

  if (promotionGroupAction === undefined) return null;

  return promotionGroupAction.configuration["group"];
};

export const getPromotionDiscountAmount = (
  discountType: DiscountType,
  promotionAction: IPromotionCouponDiscountActionResponse
): number => {
  const promotionConfiguration = promotionAction.configuration;

  if (CHANNEL_CODES.KITCHEN_DAILY in promotionConfiguration) {
    const channelConfiguration =
      promotionConfiguration[CHANNEL_CODES.KITCHEN_DAILY];

    switch (discountType) {
      case DiscountType.ORDER_PERCENTAGE:
      case DiscountType.UNIT_PERCENTAGE:
        return channelConfiguration[DiscountTypeValue.PERCENTAGE];
      case DiscountType.ORDER_FIXED:
      case DiscountType.UNIT_FIXED:
        return channelConfiguration[DiscountTypeValue.AMOUNT];
      default:
        return 0;
    }
  }

  switch (discountType) {
    case DiscountType.ORDER_PERCENTAGE:
      return promotionConfiguration[DiscountTypeValue.PERCENTAGE];
    default:
      return 0;
  }
};

export const getPromotionCouponFromResponse = (
  promotionCouponsResponse: IPromotionCouponsResponse,
  promotionCoupon: string
): BundlePromotionDetails | null => {
  const promotionDiscountAction = promotionCouponsResponse.actions
    .filter((action): action is IPromotionCouponDiscountActionResponse =>
      Object.values(DiscountType).some((value) => value === action.type)
    )
    .find((action) => Object.values(DiscountType).includes(action.type));
  if (!promotionDiscountAction) return null;

  const discountType = promotionDiscountAction.type;
  const discountAmount = getPromotionDiscountAmount(
    discountType,
    promotionDiscountAction
  );

  const groupCode = getPromotionGroupCode(promotionCouponsResponse.actions);
  const subscriptionTypeCode = getPromotionSubscriptionTypeCode(
    promotionCouponsResponse.actions
  );
  const firstOrder = isPromotionFirstOrder(promotionCouponsResponse.rules);

  return {
    promotionCoupon,
    discountType,
    discountAmount,
    firstOrder,
    groupCode,
    subscriptionTypeCode,
  };
};

export const getBundleByCode = (
  bundles: IProduct[],
  bundleCode: string
): IProduct | undefined => {
  return bundles.find((singleBundle) => singleBundle.code === bundleCode);
};

const createTab = (taxonItem: IProductTaxon): IConfigTab => ({
  code: taxonItem.taxon.code ?? "",
  label: taxonItem.taxon.name ?? "",
  position: taxonItem.taxon.position ?? 0,
});

export const serializeConfigs = (products: IProduct[]): IConfigTab[] => {
  const initConfigs: IConfigTab[] = [
    {
      code: undefined,
      label: "programmes.all",
      size: 25,
      position: -1,
    },
  ];
  const generatedTabs: IConfigTab[] = products.reduce(
    (allConfigs: IConfigTab[], item) => {
      const existingCodes = new Set(allConfigs.map((config) => config.code));

      const newConfigs = (item.productTaxons ?? []).reduce(
        (configs: IConfigTab[], taxonItem) => {
          const { taxon } = taxonItem;
          if (
            taxon.code !== undefined &&
            taxon.parent?.code === CODE_EXTRA_MAIN_TAXON &&
            !existingCodes.has(taxon.code)
          ) {
            const newTab = createTab(taxonItem);
            configs.push(newTab);
            existingCodes.add(taxon.code);
          }

          return configs;
        },
        []
      );

      return [...allConfigs, ...newConfigs];
    },
    initConfigs
  );

  return generatedTabs;
};
