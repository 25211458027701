import { useState } from "react";
import { getFormattedDate, getShippingMethodValues } from "@middleware/helpers";
import { ISchedule } from "@middleware/types";

export const useShippingMethodValues = (
  shippingMethods: ISchedule | undefined
) => {
  const daysValues = Object.keys(shippingMethods ?? {}).map((day) => ({
    label: getFormattedDate(day),
    value: day,
  }));
  const firstAvailableDay = daysValues.length > 0 ? daysValues[0].value : "";
  const methodsInitialValues = shippingMethods
    ? getShippingMethodValues(shippingMethods, firstAvailableDay)
    : [];
  const [methodsValues, setMethodsValues] = useState(methodsInitialValues);

  return { daysValues, methodsValues, setMethodsValues };
};
