/* eslint-disable */

import { FC, PropsWithChildren, useEffect, useState } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface Props {
  settings: Settings;
  currentIndex?: number;
}

export const SliderSlick: FC<PropsWithChildren<Props>> = ({
  children,
  settings,
  currentIndex,
}) => {
  const [sliderRef, setSliderRef] = useState();

  useEffect(() => {
    if (sliderRef) {
      /* eslint-disable-next-line */
      // @ts-ignore: Unreachable code error
      sliderRef.slickGoTo(currentIndex);
    }
  }, [currentIndex, sliderRef]);

  return (
    /* eslint-disable-next-line */
    // @ts-ignore: Unreachable code error
    <Slider ref={setSliderRef} {...settings}>
      {children}
    </Slider>
  );
};
