import {
  URL_PAGE_CART,
  URL_PAGE_FORMULES,
  URL_PAGE_SUCCESS,
  URL_PROGRAMME_DAILY,
  URL_PROGRAMME_GOURMANDISE,
} from "@middleware/constants";

function isPageProgramme(currentPath = "") {
  const validePages = [
    URL_PAGE_FORMULES,
    URL_PROGRAMME_DAILY,
    URL_PROGRAMME_GOURMANDISE,
    URL_PAGE_CART,
    URL_PAGE_SUCCESS,
  ];

  return validePages.includes(currentPath.replace("/[code]", ""));
}
export default isPageProgramme;
