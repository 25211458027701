import {
  CODE_BUNDLE_MAIN_TAXON,
  URL_PAGE_CART,
  URL_PAGE_FORMULES,
  URL_PAGE_FORMULES_2,
  URL_PROGRAMME_DAILY,
  URL_PROGRAMME_GOURMANDISE,
} from "@middleware/constants";
import { useCart } from "@middleware/hooks/useCart";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { UrlObject } from "url";

export const useSteps = () => {
  const { pathname } = useRouter();
  const [currentStep, setCurrentStep] = useState<number | null>(null);
  const [previousStepsUrl, setPreviousStepsUrl] = useState<
    (string | UrlObject)[]
  >([]);

  const { cart } = useCart();

  const bundleCode = cart?.items.find(
    (item) => item.productTypeTaxon === CODE_BUNDLE_MAIN_TAXON
  )?.productCode;

  useEffect(() => {
    switch (pathname) {
      case URL_PAGE_FORMULES:
      case URL_PAGE_FORMULES_2:
        setCurrentStep(1);
        setPreviousStepsUrl([]);
        break;
      case URL_PROGRAMME_DAILY:
        setPreviousStepsUrl([URL_PAGE_FORMULES]);
        setCurrentStep(2);
        break;
      case URL_PROGRAMME_GOURMANDISE:
        setPreviousStepsUrl([
          URL_PAGE_FORMULES,
          { pathname: URL_PROGRAMME_DAILY, query: { code: bundleCode } },
        ]);
        setCurrentStep(3);
        break;
      case URL_PAGE_CART:
        setPreviousStepsUrl([
          URL_PAGE_FORMULES,
          { pathname: URL_PROGRAMME_DAILY, query: { code: bundleCode } },
          URL_PROGRAMME_GOURMANDISE,
        ]);
        setCurrentStep(4);
        break;
      default:
        setCurrentStep(null);
        setPreviousStepsUrl([]);
        break;
    }
  }, [pathname, bundleCode]);

  return { currentStep, previousStepsUrl };
};
