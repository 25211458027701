import cn from "clsx";
import Image from "next/image";
import React, { FC } from "react";
import Checkbox from "react-custom-checkbox";
import { Controller } from "react-hook-form";
import { InputProps } from "@middleware/types";
import icon from "@static/images/icons/checkbox_icon.png";

export const CheckboxForm: FC<InputProps> = ({
  control,
  name,
  error,
  label,
  required,
  wrapperClass,
  dataTest,
  ...rest
}) => {
  return (
    <div className={wrapperClass}>
      <div>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <Checkbox
                onChange={(_value: boolean) => onChange(_value)}
                icon={
                  <Image src={icon} style={{ width: 18, height: 18 }} alt="" />
                }
                checked={value as boolean}
                label={label}
                borderColor="#000"
                labelClassName={cn({
                  "required-field": required,
                  checked: value as boolean,
                })}
                data-testid={dataTest}
                {...rest}
              />
            );
          }}
        />
      </div>
      {error !== undefined && (
        <span role="alert" className="erreur-field atp">
          {error}
        </span>
      )}
    </div>
  );
};
