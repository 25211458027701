import cn from "clsx";
import { Trans, useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { Button, Loader, RichText, Text } from "@components/ui";
import { getFileApiPath } from "@middleware/helpers";
import { useUI } from "@middleware/hooks";
import { IngredientsAdditionalInformation } from "@components/catalog/product/ModalProduct/IngredientsAdditionalInformation/IngredientsAdditionalInformation";
import style from "./ProductModal.module.scss";

export const ProductModal = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const { productToShow } = useUI();

  useEffect(() => {
    if (productToShow !== undefined) {
      setLoading(false);
    }
  }, [productToShow]);

  return (
    <>
      {loading ? (
        <div className="loaderContainer p-3 text-center">
          <Loader type="clip" color="#000" />
        </div>
      ) : (
        productToShow && (
          <div className={style.boxInfo}>
            <div className={style.modalContent}>
              <div className={style.row}>
                <div className={style.col}>
                  <div className={style.thumbnail}>
                    <img src={getFileApiPath(productToShow.image)} alt="" />
                  </div>
                </div>
                <div className={style.col}>
                  <div className={style.wrap}>
                    <h2 className={style.h2}>{productToShow.name}</h2>

                    <div className={style.SDesc}>
                      <RichText>
                        <Text variant="body" html={productToShow.description} />
                      </RichText>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.PTabs}>
                <div className={style.PHeaderTabs}>
                  <ul>
                    <li className={cn({ [style.active]: tab === 0 })}>
                      <Button onClick={() => setTab(0)} puce={false}>
                        <Trans
                          t={t}
                          i18nKey={"popProduct.ingredients"}
                          components={{
                            br: <br />,
                          }}
                        />
                      </Button>
                    </li>
                    <li className={cn({ [style.active]: tab === 1 })}>
                      <Button onClick={() => setTab(1)} puce={false}>
                        {t("popProduct.composition")}
                      </Button>
                    </li>
                    <li className={cn({ [style.active]: tab === 2 })}>
                      <Button onClick={() => setTab(2)} puce={false}>
                        <Trans
                          t={t}
                          i18nKey={"popProduct.tips"}
                          components={{
                            br: <br />,
                          }}
                        />
                      </Button>
                    </li>
                  </ul>
                </div>
                <div className={style.PBodyTabs}>
                  <div
                    className={cn(style.PTab, { [style.active]: tab === 0 })}
                  >
                    <IngredientsAdditionalInformation product={productToShow} />
                  </div>
                  <div
                    className={cn(style.PTab, style.nutritional, {
                      [style.active]: tab === 1,
                    })}
                  >
                    <Text variant="body" html={productToShow.nutritional} />
                  </div>
                  <div
                    className={cn(style.PTab, { [style.active]: tab === 2 })}
                  >
                    <RichText>
                      <Text variant="p" html={productToShow.preparation} />
                    </RichText>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};
