import jwt_decode from "jwt-decode";
import { FC, useCallback, useEffect, useMemo, useReducer } from "react";
import { useLocalStorage } from "react-use";
import Api from "@middleware/api";
import {
  CART_TOKEN_STORAGE,
  REMOVE_AUTH_DETAILS,
  SET_AUTH_DETAILS,
} from "@middleware/constants";
import { AuthStateContext } from "@middleware/contexts";
import {
  clearCartTokenFromStorage,
  setToStorage,
} from "@middleware/helpers/global/sessions";
import { authInitialState } from "@middleware/init";
import { authReducer } from "@middleware/reducers";
import { generateDate, isEmpty } from "@middleware/helpers";
import { AxiosResponse } from "axios";
import {
  AuthInfo,
  IFacebookLogin,
  IGoogleLogin,
  ILoginIn,
  IResponseToken,
  IUser,
  Props,
} from "@middleware/types";

export const AuthProvider: FC<Props> = ({ children }) => {
  const [value, setValue] = useLocalStorage("userInfo", authInitialState);
  const [state, dispatch] = useReducer(authReducer, value ?? authInitialState);
  useEffect(
    () => {
      if (typeof localStorage !== "undefined") {
        const newState = value ?? authInitialState;
        setValue({
          ...newState,
          ...state,
          isAuthenticated:
            state.isAuthenticated === undefined ? false : state.isAuthenticated,
        });
      }
    },
    [state] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const setAuthentication = useCallback(
    async (data: AuthInfo) => {
      return new Promise((resolve) => {
        dispatch({ type: SET_AUTH_DETAILS, data });
        resolve("resolved");
      });
    },
    [dispatch]
  );

  const clearAuthentication = useCallback(
    () => dispatch({ type: REMOVE_AUTH_DETAILS }),
    [dispatch]
  );

  const login = useCallback(
    async (res: AxiosResponse<IResponseToken>) => {
      const tokenCustomer = res.data.token;
      const cartTokenValue = res.data.tokenValue;
      const results = jwt_decode<IUser>(tokenCustomer);

      if (tokenCustomer !== "") {
        const userSession = {
          isAuthenticated: !isEmpty(results),
          user: {
            ...results,
            last_login: generateDate().getTime(),
            token: tokenCustomer,
          },
        };
        // connect customer
        await setAuthentication(userSession);
      }
      if (null !== cartTokenValue && "" !== cartTokenValue) {
        setToStorage(CART_TOKEN_STORAGE, { token: cartTokenValue });
      }
    },
    [setAuthentication]
  );

  const loginByEmail = useCallback(
    async (user: ILoginIn) => {
      const res = await Api.authentication.login(user);

      if (res.status !== 200) {
        return false;
      } else {
        await login(res as AxiosResponse<IResponseToken>);

        return true;
      }
    },
    [login]
  );

  const loginByGoogle = useCallback(
    async (user: IGoogleLogin) => {
      const res = await Api.authentication.googleLogin(user);

      if (res.status !== 200) {
        return false;
      } else {
        await login(res as AxiosResponse<IResponseToken>);

        return true;
      }
    },
    [login]
  );

  const loginByFacebook = useCallback(
    async (user: IFacebookLogin) => {
      const res = await Api.authentication.facebookLogin(user);

      if (res.status !== 200) {
        return false;
      } else {
        await login(res as AxiosResponse<IResponseToken>);

        return true;
      }
    },
    [login]
  );

  const impersonateLogin = useCallback(
    async (token: string) => {
      if (token !== "") {
        const results = jwt_decode<IUser>(token);
        const userSession = {
          isAuthenticated: !isEmpty(results),
          user: {
            ...results,
            last_login: generateDate().getTime(),
            token: token,
          },
        };
        // connect customer
        await setAuthentication(userSession);

        return userSession;
      }

      return { isAuthenticated: false, user: null };
    },
    [setAuthentication]
  );

  const logoutUser = useCallback(() => {
    clearAuthentication();
    clearCartTokenFromStorage();
  }, [clearAuthentication]);

  const values = useMemo(
    () => ({
      ...state,
      loginByEmail,
      loginByGoogle,
      loginByFacebook,
      impersonateLogin,
      logoutUser,
    }),
    [state] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <AuthStateContext.Provider value={values}>
      {children}
    </AuthStateContext.Provider>
  );
};
