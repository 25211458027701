import cn from "clsx";
import Image from "next/image";
import { useEffect, useState } from "react";
import Picto from "@static/images/icons/disable_toogle.png";
import style from "./Toggle.module.scss";

interface Props {
  labelOff: string;
  labelOn: string;
  toggled: boolean;
  onClick: (isToggled: boolean) => void;
  disabled: boolean;
}

export const Toggle = ({
  labelOff,
  labelOn,
  toggled,
  onClick,
  disabled,
}: Props) => {
  const [isToggled, toggle] = useState(toggled);

  useEffect(() => {
    toggle(toggled);
  }, [toggled]);

  const handleOnClick = () => {
    toggle(!isToggled);
    onClick(!isToggled);
  };

  return (
    <div
      className={cn(
        style.root,
        { [style.disabled]: disabled },
        { [style.off]: !isToggled }
      )}
      data-testid="toggle-container"
    >
      <strong className={style.left}>{labelOff}</strong>
      <label>
        <input
          type="checkbox"
          checked={isToggled}
          onClick={handleOnClick}
          data-testid="toggle"
          readOnly
        />
        <span className={style.customInput}>
          {disabled && <Image src={Picto} width="18" height="18" alt="" />}
        </span>
      </label>
      <strong className={style.right}>{labelOn}</strong>
    </div>
  );
};
