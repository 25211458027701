import { getOrderMeals } from "@middleware/helpers";
import { IControl, IOrder, IProduct } from "@middleware/types";
import { useEffect, useMemo, useState } from "react";

export const useProgram = (
  products: IProduct[],
  order: IOrder | undefined,
  mealsNumber = 0,
  isExtras = false
) => {
  const { mealsCount } = getOrderMeals(order);
  const isSameFormula = mealsNumber === mealsCount || isExtras;
  const initControl: IControl = useMemo(
    () => ({
      products: products.map((product) => {
        return {
          id: product.id,
          quantity: isSameFormula
            ? order?.items.find(
                (item) => item.variantCode === product.codeVariant
              )?.quantity ?? 0
            : 0,
          code: product.codeVariant,
          price: product.price,
          packaging: product.packaging,
        };
      }),
    }),
    [order, products, isSameFormula]
  );

  const [controle, setControle] = useState<IControl>(initControl);

  const resetControl = () =>
    setControle({
      products: products.map((product) => ({
        id: product.id,
        quantity: 0,
        code: product.codeVariant,
        price: product.price,
        packaging: product.packaging,
      })),
    });

  const updateQuantityOnProductsFiltered = (currentProducts: IProduct[]) => {
    const newControl = {
      products: currentProducts.map((product) => {
        return {
          id: product.id,
          quantity:
            controle.products.find((prod) => prod.id === product.id)
              ?.quantity ?? 0,
          code: product.codeVariant,
          price: product.price,
          packaging: product.packaging,
        };
      }),
    };
    setControle(newControl);
  };

  const setQuantity = (idProduct: number, quantity: number) => {
    const curentControle = { ...controle } as IControl;

    curentControle.products.map((product, i) => {
      if (product.id === idProduct) {
        curentControle.products[i].quantity = quantity;
      }

      return true;
    });
    setControle(curentControle);
  };

  const setDefaultQuantity = (
    selectedProducts: IProduct[],
    formula: number
  ) => {
    const lengthProducts = selectedProducts.length;
    const defaultQty = Math.floor(formula / lengthProducts);
    const remainer = formula - lengthProducts * defaultQty;

    const newControl = {
      products: selectedProducts.map((product, index) => {
        return {
          id: product.id,
          quantity: defaultQty + (index < remainer ? 1 : 0),
          code: product.codeVariant,
          price: product.price,
          packaging: product.packaging,
        };
      }),
    };
    setControle(newControl);
  };

  useEffect(() => {
    const totalQuantity = initControl.products.reduce(
      (accum, item) => (accum += item.quantity),
      0
    );
    if (mealsNumber > 0 && totalQuantity !== mealsNumber) {
      setDefaultQuantity(products, mealsNumber);

      return;
    }
    setControle(initControl);

    // pre-selection only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initControl]);

  return {
    controle,
    resetControl,
    updateQuantityOnProductsFiltered,
    setDefaultQuantity,
    setQuantity,
  };
};
