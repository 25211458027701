import { useCallback, useEffect, useState } from "react";
import { ICustomer, IOrder, IProduct } from "@middleware/types";
import { getOrderMeals } from "@middleware/helpers";
import { FOOD_PREFERENCE_FILTERS } from "@middleware/constants";
import { useLocalStorage } from "react-use";
import { useCustomer } from "./useCustomer";

export const useFoodPreferencesFilters = (
  order: IOrder | undefined,
  meals: IProduct[]
): [
  selectedFilters: Set<string>,
  setSelectedFilters: (code: string[]) => void,
  customer: ICustomer | undefined
] => {
  const { customer } = useCustomer();
  const [filtersLocalStorage, setFiltersLocalStorage] = useLocalStorage<
    string[]
  >(FOOD_PREFERENCE_FILTERS, []);
  const [selectedFilters, setSelectedFilters] = useState<Set<string>>(
    new Set()
  );

  const getValidOrderFilters = useCallback(
    (filters: string[]) => {
      if (!order) return new Set([]);

      const { mealsItems } = getOrderMeals(order);

      const filtersWithNoMealsInCart = filters.filter(
        (filter) =>
          mealsItems.filter((item) => {
            if (item.quantity === 0) return false;

            // foodPreference is only in meals array not in order items
            const currentMeal = meals.find(
              (meal) => meal.codeVariant === item.variantCode
            );

            return currentMeal?.foodPreference.includes(filter);
          }).length === 0
      );

      return new Set(filtersWithNoMealsInCart);
    },
    [order, meals]
  );

  useEffect(() => {
    setSelectedFilters(new Set(filtersLocalStorage));
  }, [filtersLocalStorage]);

  useEffect(() => {
    if (customer === undefined) return;

    if (customer.excludedFoodPreferences.length > 0) {
      const newSelectedFilter = getValidOrderFilters(
        customer.excludedFoodPreferences
      );
      setFiltersLocalStorage(Array.from(newSelectedFilter));
    }
  }, [customer, setFiltersLocalStorage, getValidOrderFilters]);

  useEffect(() => {
    if (order !== undefined && order.items.length > 0) {
      const newSelectedFilters = getValidOrderFilters(
        filtersLocalStorage ?? []
      );
      setSelectedFilters(newSelectedFilters);
    }

    // we use setSelectedFilters her
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, meals]);

  const toggleFilter = (codes: string[]) => {
    setFiltersLocalStorage(codes);
  };

  return [selectedFilters, toggleFilter, customer];
};
