import { TReferral, TReferralListResponse } from "@middleware/types";
import { getFormattedDate } from "./utils";

export const serializeReferralsList = (
  referralsList: TReferralListResponse[]
) => {
  return referralsList.map(
    (item) =>
      ({
        email: item.email,
        status: item.state,
        date: getFormattedDate(item.sponsoredAt, {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        commandDate:
          item.state === "customer"
            ? getFormattedDate(item.customerSince, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            : "",
      } as TReferral)
  );
};
