import { pushUserDataLayer } from "@middleware/helpers";
import { useEffect } from "react";
import { useCustomer } from "./useCustomer";

export const usePushCustomerDataLayer = () => {
  const { customer } = useCustomer();

  useEffect(() => {
    if (customer) {
      pushUserDataLayer(customer);
    }
  }, [customer]);
};
