import cn from "clsx";
import Image from "next/image";
import { FC, ReactNode } from "react";
import { UrlObject } from "url";
import style from "./EditButton.module.scss";
import { Link } from "../Link/Link";
import { Text } from "../Text/Text";

interface Props {
  editUrl?: string | UrlObject;
  children: ReactNode;
  variant?: "default" | "primary";
  puce?: boolean;
}
export const EditButton: FC<Props> = ({
  editUrl,
  children,
  variant = "default",
  puce = true,
}) => {
  return editUrl !== undefined ? (
    <div className={cn(style.root, style[variant], { [style.puce]: puce })}>
      <Link href={editUrl} className="editBtn rounded simple">
        <Text variant="span">{children}</Text>
        {puce && (
          <Image
            alt=""
            src={"/static/images/icons/black-pencil.svg"}
            width="16"
            height="17"
          />
        )}
      </Link>
    </div>
  ) : null;
};
