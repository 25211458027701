import {
  CODE_TAB_ACCOUNT,
  CODE_TAB_LOYALTY,
  CODE_TAB_ORDERS,
  URL_PAGE_CUSTOMER_INFORMATION,
  URL_PAGE_CUSTOMER_LOYALTY_POINTS,
  URL_PAGE_CUSTOMER_SUBSCRIPTION,
  URL_PAGE_CUSTOMER_UPDATE_PASSWORD,
  URL_PAGE_LOYALTY_SUBMENU_MOBILE,
} from "@middleware/constants";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useAccountPage = () => {
  const { pathname } = useRouter();
  const [activeTab, setActiveTab] = useState<string>(CODE_TAB_ORDERS);

  useEffect(() => {
    switch (pathname) {
      case URL_PAGE_CUSTOMER_SUBSCRIPTION:
      case URL_PAGE_CUSTOMER_INFORMATION:
      case URL_PAGE_CUSTOMER_UPDATE_PASSWORD:
        setActiveTab(CODE_TAB_ACCOUNT);
        break;
      case URL_PAGE_LOYALTY_SUBMENU_MOBILE:
      case URL_PAGE_CUSTOMER_LOYALTY_POINTS:
        setActiveTab(CODE_TAB_LOYALTY);
        break;
      default:
        break;
    }
  }, [pathname]);

  return { activeTab, setActiveTab };
};
