import { getOrderOffered } from "@middleware/helpers";
import { IOrder, IOrderItem } from "@middleware/types";
import { useEffect, useState } from "react";

export const useMealsOffered = (order?: IOrder) => {
  const [offered, setOffered] = useState<IOrderItem[]>([]);

  useEffect(() => {
    if (order) {
      const { offeredItems } = getOrderOffered(order);
      setOffered(offeredItems);
    }
  }, [order]);

  return { offered };
};
