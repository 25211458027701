import { REMOVE_AUTH_DETAILS, SET_AUTH_DETAILS } from "@middleware/constants";
import { authInitialState } from "@middleware/init";
import { AuthActionType, AuthInfo } from "@middleware/types";

export const authReducer = (state: AuthInfo, action: AuthActionType) => {
  switch (action.type) {
    case SET_AUTH_DETAILS:
      return {
        ...state,
        isAuthenticated: action.data.isAuthenticated,
        user: action.data.user,
      };
    case REMOVE_AUTH_DETAILS:
      return {
        ...state,
        isAuthenticated: authInitialState.isAuthenticated,
        user: authInitialState.user,
      };
    default:
      throw new Error(`Unhandled action type`);
  }
};
