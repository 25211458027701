import KRGlue from "@lyracom/embedded-form-glue";
import { client as env } from "@config/env/client";
import { URL_PAGE_CART } from "@middleware/constants";

export const loadPayzenLibrary = async () => {
  const { KR } = await KRGlue.loadLibrary(
    env.NEXT_PUBLIC_STATIC_PAYZEN,
    env.NEXT_PUBLIC_PAYZEN_PUBLIC_KEY
  );

  return KR;
};

export const initializePaymentForm = async (formToken: string, KR: KR) => {
  await KR.setFormConfig({
    formToken,
    "kr-language": "fr-FR",
    "kr-get-url-refused": URL_PAGE_CART,
  });

  return { KR };
};
