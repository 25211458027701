import {
  URL_PAGE_CART,
  URL_PAGE_CUSTOMER_ORDER,
  URL_PROGRAMME_DAILY,
  URL_PROGRAMME_GOURMANDISE,
} from "@middleware/constants";
import { isCustomerSubscribed } from "@middleware/helpers";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useCustomer } from "./useCustomer";

export const useRedirectIfSubscribed = () => {
  const { pathname } = useRouter();
  const router = useRouter();
  const { customer } = useCustomer();

  useEffect(() => {
    const pagesArray = [
      URL_PAGE_CART,
      URL_PROGRAMME_DAILY,
      URL_PROGRAMME_GOURMANDISE,
    ];

    const checkUserSubscription = async () => {
      if (!customer) return;

      if (isCustomerSubscribed(customer)) {
        if (pagesArray.includes(pathname)) {
          await router.push(
            {
              pathname: URL_PAGE_CUSTOMER_ORDER,
              query: {
                alreadySubscribed: "true",
              },
            },
            URL_PAGE_CUSTOMER_ORDER
          );
        }
      }
    };
    void checkUserSubscription();
  }, [customer, pathname, router]);
};
