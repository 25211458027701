import Api from "@middleware/api";
import { CODE_PROMO, DEFAULT_PROMOTION } from "@middleware/constants";
import {
  getFromStorage,
  setToStorage,
} from "@middleware/helpers/global/sessions";
import { BundlePromotionDetails } from "@middleware/types";
import { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";

export const usePromotionDetails = () => {
  const [storagePromotion, setStoragePromotion] =
    useLocalStorage<BundlePromotionDetails>(DEFAULT_PROMOTION);
  const promotionCoupon = getFromStorage(CODE_PROMO);
  const [promotion, setPromotion] = useState(storagePromotion);

  useEffect(() => {
    const fetchPromotionDetails = async () => {
      if (
        typeof promotionCoupon === "string" &&
        promotionCoupon !== "" &&
        storagePromotion?.promotionCoupon !== promotionCoupon
      ) {
        const promotionDetails = await Api.catalog.getCouponPromotion(
          promotionCoupon
        );
        if (promotionDetails) {
          setPromotion(promotionDetails);
          setStoragePromotion(promotionDetails);
          setToStorage(CODE_PROMO, promotionDetails.promotionCoupon);
        }
      }
    };

    void fetchPromotionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionCoupon]);

  return { promotion, setPromotion };
};
