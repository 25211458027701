import React, { FC, useCallback, useMemo, useReducer } from "react";
import { REMOVE_DETAILS, SET_DETAILS } from "@middleware/constants";
import { GlobalMessagingContext } from "@middleware/contexts";
import { globalStatusInitialState } from "@middleware/init";
import { messagingReducer } from "@middleware/reducers";
import { IMessage, Props } from "@middleware/types";

export const GlobalMessagingProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(
    messagingReducer,
    globalStatusInitialState
  );

  const setMessage = useCallback(
    (message: IMessage) => dispatch({ type: SET_DETAILS, message }),
    [dispatch]
  );

  const cleanMessage = useCallback(
    () => dispatch({ type: REMOVE_DETAILS }),
    [dispatch]
  );

  const value = useMemo(
    () => ({
      ...state,
      setMessage,
      cleanMessage,
    }),
    [state] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <GlobalMessagingContext.Provider value={value}>
      {children}
    </GlobalMessagingContext.Provider>
  );
};
