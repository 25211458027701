import cn from "clsx";
import { useTranslation } from "next-i18next";
import { FC, MouseEventHandler, SyntheticEvent } from "react";
import { Logo, Menu } from "@components/commun";
import { Link } from "@components/ui";
import { useAuth } from "@middleware/hooks";
import { EDevice } from "@middleware/types";
import {
  URL_HOME,
  URL_PAGE_CART,
  URL_PAGE_CUSTOMER_ORDER,
} from "@middleware/constants";
import Image from "next/image";
import phonePicto from "@static/images/header/picto-phone-ref.png";
import comptePicto from "@static/images/header/picto-compte-ref.png";
import cartPicto from "@static/images/header/picto-panier-ref.png";
import styles from "./NavBar.module.scss";
export interface Props {
  showModal: MouseEventHandler;
  logout: (event: SyntheticEvent) => Promise<void>;
}

export const NavBar: FC<Props> = ({ showModal, logout }) => {
  const { t } = useTranslation("header");
  const { isAuthenticated } = useAuth();

  return (
    <>
      <Menu device={EDevice.DESKTOP} showModal={showModal} logout={logout} />
      <div className={cn(styles.brand)}>
        <Link href={URL_HOME} className="simple">
          <Logo />
        </Link>
      </div>
      <div className={styles.rightNav}>
        <ul>
          <li className={styles.linkNumber}>
            <a href={`tel:${t("menu.pPhoneNum")}`}>
              <span className={styles.icon}>
                <Image src={phonePicto} alt="" width={22} />
              </span>
              <span className={styles.tel}>
                {t("menu.pPhoneLabel")}
                <span className={styles.label}>{t("menu.appelLocal")}</span>
              </span>
            </a>
          </li>
          <li className={cn(styles.linkCompte, "hide-tab-mobile")}>
            {isAuthenticated === true ? (
              <>
                <Link href={URL_PAGE_CUSTOMER_ORDER} className="simple">
                  {t("menu.pMonEspace")}{" "}
                </Link>
                <a href="#!" className={styles.deconnect} onClick={logout}>
                  {t("menu.pDeconnexion")}
                </a>
              </>
            ) : (
              <a href="#!" onClick={(e) => showModal(e)} data-cy="modalLogin">
                <Image src={comptePicto} alt="" width={22} />
              </a>
            )}
          </li>
          <li className={cn("hide-tab-mobile", styles.linkCart)}>
            <Link href={URL_PAGE_CART} className="simple" prefetch={false}>
              <Image src={cartPicto} alt="" width={23} />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};
