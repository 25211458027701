import { AuthInfo, StateAuth } from "@middleware/types";

const initialLogin = async (): Promise<boolean> =>
  new Promise((resolve) => {
    resolve(false);
  });

export const authInitialState: StateAuth = {
  isAuthenticated: undefined,
  user: null,
  loginByEmail: initialLogin,
  loginByGoogle: initialLogin,
  loginByFacebook: initialLogin,
  impersonateLogin: async (): Promise<AuthInfo> =>
    new Promise((resolve) => {
      resolve({ isAuthenticated: false, user: null });
    }),
  logoutUser: () => undefined,
};
