import {
  FC,
  MouseEventHandler,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { useCustomer } from "@middleware/hooks";
import { EDevice, ESubscriptionState } from "@middleware/types";
import { DesktopMenu } from "./DesktopMenu";
import { MobileMenu } from "./MobileMenu";

export interface Props {
  device: EDevice;
  showModal: MouseEventHandler;
  logout: (event: SyntheticEvent) => Promise<void>;
}

export const Menu: FC<Props> = ({ device, showModal, logout }) => {
  const { customer } = useCustomer();
  const [showFormulas, setShowFormulas] = useState(true);

  useEffect(() => {
    if (customer) {
      const state =
        customer.currentSubscription?.state ?? ESubscriptionState.DONE;
      if (
        [ESubscriptionState.ON_GOING, ESubscriptionState.PAUSED].includes(state)
      )
        setShowFormulas(false);
    }
  }, [customer]);

  return (
    <>
      {device === EDevice.DESKTOP ? (
        <DesktopMenu showFormulas={showFormulas} />
      ) : (
        <MobileMenu
          showFormulas={showFormulas}
          showModal={showModal}
          logout={logout}
        />
      )}
    </>
  );
};
