import { IShemaMessage } from "@middleware/types";
import { useTranslation } from "next-i18next";

export const useSchemaMessages = (): IShemaMessage => {
  const { t } = useTranslation();

  const defaultNamespace = { ns: "validation" };

  return {
    requiredField: t("requiredField", defaultNamespace) ?? "",
    completeField: t("completeField", defaultNamespace) ?? "",
    invalidField: t("invalidField", defaultNamespace) ?? "",
    invalidEmail: t("invalidEmail", defaultNamespace) ?? "",
    passwordMax: t("passwordMax", defaultNamespace) ?? "",
    passwordMin: t("passwordMin", defaultNamespace) ?? "",
    passwordConfirm: t("passwordConfirm", defaultNamespace) ?? "",
    agreementCheck: t("agreementCheck", defaultNamespace) ?? "",
    agreeTerms: t("agreeTerms", defaultNamespace) ?? "",
    evaluationAgreementCheck:
      t("evaluationAgreementCheck", defaultNamespace) ?? "",
    champsInvalide: t("champsInvalide", defaultNamespace) ?? "",
    numberPositive: t("numberPositive", defaultNamespace) ?? "",
    chooseOption: t("chooseOption", defaultNamespace) ?? "",
    stringNoNumberValidator:
      t("stringNoNumberValidator", defaultNamespace) ?? "",
    invalidPhoneMobile: t("invalidPhoneMobile", defaultNamespace) ?? "",
    invalidLandlinePhone: t("invalidLandlinePhone", defaultNamespace) ?? "",
  };
};
