import cn from "clsx";
import { useTranslation } from "next-i18next";
import { FC, MouseEventHandler, SyntheticEvent } from "react";
import { Link } from "@components/ui";
import { useAuth, useUI } from "@middleware/hooks";
import {
  URL_PAGE_CUSTOMER_ORDER,
  URL_PAGE_FAQ,
  URL_PAGE_FORMULES,
  URL_PAGE_HISTOIRE,
  URL_PAGE_MANGER,
} from "@middleware/constants";
import style from "./Menu.module.scss";

export interface Props {
  showFormulas: boolean;
  showModal: MouseEventHandler;
  logout: (event: SyntheticEvent) => Promise<void>;
}
export const MobileMenu: FC<Props> = ({ showFormulas, showModal, logout }) => {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation("header");
  const { closeDropdown } = useUI();

  return (
    <ul className={style.mobile}>
      <li className={style.number}>
        <a href={`tel:${t("menu.pPhoneNum")}`}>
          {t("menu.pPhoneLabel")}
          <span>{t("menu.appelLocal")}</span>
        </a>
        {isAuthenticated === true && (
          <Link
            className={cn("simple", style.compteMobile)}
            href={URL_PAGE_CUSTOMER_ORDER}
            onClick={closeDropdown}
          >
            {t("menu.pMonEspace")}
          </Link>
        )}
        {isAuthenticated !== true && (
          <a
            className={style.compteMobile}
            href="#!"
            onClick={(e) => showModal(e)}
          >
            {t("menu.pCompte")}
          </a>
        )}
      </li>
      <li>
        <Link href={URL_PAGE_FAQ} className="simple" onClick={closeDropdown}>
          {t("menu.pFaq")}
        </Link>
      </li>
      <li>
        <Link
          className="simple"
          href={URL_PAGE_HISTOIRE}
          onClick={closeDropdown}
        >
          {t("menu.pHistoire")}
        </Link>
      </li>
      <li>
        <Link href={URL_PAGE_MANGER} className="simple" onClick={closeDropdown}>
          {t("menu.pMangerEquilibre")}
        </Link>
      </li>
      {showFormulas && (
        <li>
          <Link
            href={URL_PAGE_FORMULES}
            className="simple"
            onClick={closeDropdown}
          >
            {t("menu.pFormules")}
          </Link>
        </li>
      )}

      {isAuthenticated === true && (
        <li>
          <a href="#!" onClick={logout}>
            {t("menu.pDeconnexion")}
          </a>
        </li>
      )}
    </ul>
  );
};
