import { getOrderExtras, hasCustomBundle } from "@middleware/helpers";
import { IOrderItem } from "@middleware/types";
import { useEffect, useState } from "react";
import { useCart } from "./useCart";

export const useExtras = () => {
  const { cart } = useCart();
  const [extras, setExtras] = useState<IOrderItem[]>([]);
  const [totalExtras, setTotal] = useState(0);
  const [isCustomBundle, setHasCustomBundle] = useState(false);

  useEffect(() => {
    if (cart) {
      const { extrasItems, extrasTotal } = getOrderExtras(cart);
      setExtras(extrasItems);
      setTotal(extrasTotal);
      setHasCustomBundle(hasCustomBundle(cart));
    }
  }, [cart]);

  return { extras, totalExtras, isCustomBundle };
};
