import Authentication from "./authentication";
import Catalog from "./catalog";
import Cart from "./cart";
import Customer from "./customer";

const Api = {
  authentication: new Authentication(),
  catalog: new Catalog(),
  cart: new Cart(),
  customer: new Customer(),
};

export default Api;
