import { FC } from "react";
import { useTranslation } from "next-i18next";
import { Link } from "@components/ui";
import {
  URL_PAGE_FAQ,
  URL_PAGE_FORMULES,
  URL_PAGE_HISTOIRE,
  URL_PAGE_MANGER,
} from "@middleware/constants";
import style from "./Menu.module.scss";

export interface Props {
  showFormulas: boolean;
}

export const DesktopMenu: FC<Props> = ({ showFormulas }) => {
  const { t } = useTranslation("header");

  return (
    <menu className={style.menu}>
      <nav className={style.navigation}>
        <ul>
          <li>
            <Link href={URL_PAGE_FAQ} className="simple">
              {t("menu.pFaq")}
            </Link>
          </li>
          <li>
            <Link className="simple" href={URL_PAGE_HISTOIRE}>
              {t("menu.pHistoire")}
            </Link>
          </li>
          <li>
            <Link href={URL_PAGE_MANGER} className="simple">
              {t("menu.pMangerEquilibre")}
            </Link>
          </li>
          {showFormulas && (
            <li>
              <Link href={URL_PAGE_FORMULES} className="simple">
                {t("menu.pFormules")}
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </menu>
  );
};
