import { IgetResourceUrl, IOrder, IRequest } from "@middleware/types";
import { client as env } from "@config/env/client";
import {
  URL_PAGE_CUSTOMER_ORDER,
  URL_PAGE_CUSTOMER_ORDER_PAST,
} from "@middleware/constants";
import { AxiosResponse } from "axios";
import { getOrderDetails } from "./utils";
import { createUrl } from "./router";

export const getApiUrlMedia = (): string => {
  return env.NEXT_PUBLIC_API_URL;
};

export const getFileApiPath = (file: string) => {
  return getApiUrlMedia() + file;
};

export const isValidHttpUrl = (str: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );

  return !!pattern.test(str);
};
export const getApiResourcePublicUrl = (
  typeApi: "api",
  endPointStart: string | null
): string => {
  let apiUrl = "";
  switch (typeApi) {
    case "api":
      apiUrl = env.NEXT_PUBLIC_API_URL;
      break;
    default:
      apiUrl = env.NEXT_PUBLIC_API_URL;
      break;
  }

  return `${apiUrl}${endPointStart ?? ""}`;
};

export const getResourceUrl: IgetResourceUrl = (
  ressourceType,
  endPointStart,
  ressourceLocator,
  pathParams
) => {
  const baseUrl =
    getApiResourcePublicUrl(ressourceType, endPointStart) + ressourceLocator;

  return createUrl(baseUrl, pathParams);
};

export const getSummaryOrdersPageUrl = (cart: IOrder) => {
  const { isPastShipment } = getOrderDetails(cart);
  const sammaryUrl = isPastShipment
    ? URL_PAGE_CUSTOMER_ORDER_PAST
    : URL_PAGE_CUSTOMER_ORDER;

  return sammaryUrl;
};

export const isFacebookRequest = (response: AxiosResponse) => {
  const request = response.request as IRequest;

  return request.responseURL.includes("facebook-authentication-token");
};
