import Api from "@middleware/api";
import { IOrder } from "@middleware/types";
import { useEffect, useState } from "react";

export const useOrder = (shipmentOrderTokenValue: string) => {
  const [order, setOrder] = useState<IOrder>();

  useEffect(() => {
    const getOrder = async () => {
      if (shipmentOrderTokenValue.length === 0) return false;

      const customerOrder = await Api.cart.getCartByToken(
        shipmentOrderTokenValue,
        false
      );
      setOrder(customerOrder);
    };
    void getOrder();
  }, [shipmentOrderTokenValue]);

  return { order, setOrder };
};
