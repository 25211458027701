import cn from "clsx";
import React, { FC } from "react";
import { Text } from "@components/ui";
import { InputProps } from "@middleware/types";

export const Input: FC<InputProps> = ({
  register,
  name,
  type,
  error,
  label,
  required,
  wrapperClass,
  ...rest
}) => {
  return (
    <div className={wrapperClass}>
      {label !== undefined && (
        <label htmlFor={name} className={cn({ "required-field": required })}>
          <Text variant="span" html={label} />
        </label>
      )}
      <input
        type={type}
        data-testid={name}
        aria-invalid={error !== "" ? "true" : "false"}
        {...register?.(name)}
        {...rest}
      />
      {error !== undefined && (
        <span role="alert" className="erreur-field inLeft">
          {error}
        </span>
      )}
    </div>
  );
};
