import { useEffect } from "react";

export const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement("script");

    setTimeout(() => {
      script.src = url;
      script.id = "easiwebform-4";
      script.async = true;

      document.body.appendChild(script);
    }, 1800);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};
