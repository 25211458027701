import {
  FRENCH_COUNTRY_CODE,
  VALID_FRENCH_FIXE_NUMBER_START,
  VALID_FRENCH_PHONE_NUMBER_START,
} from "@middleware/constants";

export const validateFrenchMobilePhone = (
  phoneNumber: string,
  contryCode: string
) => {
  const validMobilePhoneStartList = VALID_FRENCH_PHONE_NUMBER_START;
  const mobilePhoneStart = phoneNumber.substring(0, 1);
  if (
    contryCode === FRENCH_COUNTRY_CODE &&
    (phoneNumber.length !== 9 ||
      !validMobilePhoneStartList.includes(mobilePhoneStart))
  ) {
    return false;
  }

  return true;
};
export const validateFrenchLandlinePhone = (
  phoneNumber: string,
  contryCode: string
) => {
  const validLandlinePhoneStartList = VALID_FRENCH_FIXE_NUMBER_START;
  const LandlinePhoneStart = phoneNumber.substring(0, 1);
  if (
    contryCode === FRENCH_COUNTRY_CODE &&
    (phoneNumber.length !== 9 ||
      !validLandlinePhoneStartList.includes(LandlinePhoneStart))
  ) {
    return false;
  }

  return true;
};
export const isValidMobilePhone = (phoneNumber: string): boolean => {
  const finalNumber = phoneNumber.toString().replace(/[- .()]/g, "");
  const contryCode = finalNumber.substring(0, 2);
  const mobilePhone = finalNumber.replace(contryCode, "");

  if (!validateFrenchMobilePhone(mobilePhone, contryCode)) return false;

  return true;
};
export const isValidLandlinePhone = (phoneNumber: string): boolean => {
  const finalNumber = phoneNumber.toString().replace(/[- .()]/g, "");
  const contryCode = finalNumber.substring(0, 2);
  const LandlinePhone = finalNumber.replace(contryCode, "");

  if (!validateFrenchLandlinePhone(LandlinePhone, contryCode)) return false;

  return true;
};
