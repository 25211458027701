export const BIG_TABLET_SIZE = 1023;
export const TABLET_SIZE = 768;
export const SMALL_TABLET_SIZE = 630;
export const LOADER_COLOR = "rgb(118, 191, 114)";
export const PRIMARY_COLOR = "rgb(118, 191, 114)";
export const GREY_COLOR = "rgb(146, 146, 146)";
export const CODE_TAB_ACCOUNT = "account";
export const CODE_TAB_ORDERS = "orders";
export const CODE_TAB_LOYALTY = "credits";
export const CODE_TAB_ORDERS_COMING = "coming";
export const CODE_TAB_ORDERS_PAST = "past";
export const CODE_TAB_INFOS = "informations";
export const CODE_TAB_SUBSCRIPTION = "subscription";
export const CODE_TAB_PASSWORD = "password";
export const CODE_TAB_CREDITS = "points";
export const CODE_TAB_BALANCE = "balance";
export const ID_MODAL = "modal-contenaire";
