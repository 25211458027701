import WrapApi from "@middleware/helpers/api/wrapApi.class";
import {
  BundlePromotion,
  BundlePromotionDetails,
  IProduct,
  IPromotionCouponsResponse,
} from "@middleware/types";
import {
  getProductsFromResponse,
  getProductsOfMenu,
  getPromotionCouponFromResponse,
} from "@middleware/helpers";
import { HttpStatusCode } from "axios";

export default class Catalog extends WrapApi {
  getBundles = async (order = "ASC"): Promise<IProduct[]> => {
    const bundlesResponses = await this.getResource(
      "shop/products/type/{type}",
      { type: "bundle" }
    );

    return getProductsFromResponse(bundlesResponses, order);
  };

  getMeals = async (order = "ASC"): Promise<IProduct[]> => {
    const mealsResponses = await this.getResource("shop/products", null, {
      "productTaxons.taxon.code": "MEAL",
    });

    return getProductsFromResponse(mealsResponses, order);
  };

  getWeeklyMeals = async (param = "current"): Promise<IProduct[]> => {
    const mealsResponses = await this.getResource("shop/menus/{param}", {
      param,
    });

    return getProductsOfMenu(mealsResponses);
  };

  getExtras = async (order = "ASC"): Promise<IProduct[]> => {
    const extrasResponses = await this.getResource(
      "shop/products/type/{type}",
      { type: "EXTRA" }
    );

    return getProductsFromResponse(extrasResponses, order);
  };

  getBundlePromotions = async (): Promise<BundlePromotion[]> => {
    const bundlePromotionsResponses = await this.getResource(
      "shop/promotions/bundles"
    );

    return bundlePromotionsResponses.data as BundlePromotion[];
  };

  getCouponPromotion = async (
    code: string
  ): Promise<BundlePromotionDetails | null> => {
    const promotionResponse = await this.getResource(
      `shop/promotion-coupons/${code}/promotion`
    );

    if (promotionResponse.status === HttpStatusCode.Ok)
      return getPromotionCouponFromResponse(
        promotionResponse.data as IPromotionCouponsResponse,
        code
      );

    return null;
  };
}
