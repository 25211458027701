import Api from "@middleware/api";
import { getNumericDate } from "@middleware/helpers";
import { IOrder, IProduct } from "@middleware/types";
import { useEffect, useState } from "react";

export const useWeeklyMeals = (order?: IOrder) => {
  const [meals, setMeals] = useState<IProduct[]>();

  useEffect(() => {
    const getWeeklyMeals = async () => {
      if (order) {
        const weekDate = getNumericDate(order.chosenDeliveryDate);
        const currentMeals = await Api.catalog.getWeeklyMeals(weekDate);
        setMeals(currentMeals);
      }
    };
    void getWeeklyMeals();
  }, [order]);

  return { meals, setMeals };
};
