import {
  URL_HOME,
  URL_PAGE_BOX_REPAS_MANGER_EQUILIBRE,
  URL_PAGE_CGU,
  URL_PAGE_CGV,
  URL_PAGE_CONTACT,
  URL_PAGE_CUSTOMER_LOYALTY,
  URL_PAGE_ENTREPRISE,
  URL_PAGE_FAQ,
  URL_PAGE_FORMULES,
  URL_PAGE_HISTOIRE,
  URL_PAGE_MANGER,
  URL_PAGE_PANIER_RECETTE_PERFECTIONNER_CUISINE,
  URL_PAGE_PANIER_REPAS_ENVIE_CUISINER,
  URL_PAGE_PANIERS_RECETTE_CUISINE_FAMILLE,
  URL_PAGE_PANIERS_REPAS_EVITER_COURSES,
  URL_PAGE_PLAT_CUISINE_SOLUTION_MALBOUFFE,
  URL_PAGE_PLAT_PREPARE_SOLUTION,
  URL_PAGE_SUCCESS,
} from "@middleware/constants";

export const prismicRoutes = [
  {
    type: "home_page",
    path: URL_HOME,
  },
  {
    type: "page_faq",
    path: URL_PAGE_FAQ,
  },
  {
    type: "page_histoire",
    path: URL_PAGE_HISTOIRE,
  },
  {
    type: "page_manger_equilibre",
    path: URL_PAGE_MANGER,
  },
  {
    type: "page_entreprise",
    path: URL_PAGE_ENTREPRISE,
  },
  {
    type: "bilan_questions",
    path: URL_PAGE_FORMULES,
  },
  {
    type: "bilan_results",
    path: URL_PAGE_FORMULES,
  },
  {
    type: "bloc_goute",
    path: URL_PAGE_FORMULES,
  },
  {
    type: "page_cgv",
    path: URL_PAGE_CGV,
  },
  {
    type: "page_cgu",
    path: URL_PAGE_CGU,
  },
  {
    type: "page_contacts",
    path: URL_PAGE_CONTACT,
  },
  {
    type: "page_parrainage",
    path: URL_PAGE_CUSTOMER_LOYALTY,
  },
  {
    type: "page_success",
    path: URL_PAGE_SUCCESS,
  },
  {
    type: "page_plat_prepare_solution_manger_equilibre",
    path: URL_PAGE_PLAT_PREPARE_SOLUTION,
  },
  {
    type: "plat_cuisine_solution_malbouffe",
    path: URL_PAGE_PLAT_CUISINE_SOLUTION_MALBOUFFE,
  },
  {
    type: "paniers_repas_eviter_les_courses",
    path: URL_PAGE_PANIERS_REPAS_EVITER_COURSES,
  },
  {
    type: "paniers_recette_cuisine_famille",
    path: URL_PAGE_PANIERS_RECETTE_CUISINE_FAMILLE,
  },
  {
    type: "paniers_recette_cuisine_famille",
    path: URL_PAGE_PANIER_REPAS_ENVIE_CUISINER,
  },
  {
    type: "panier_repas_envie_de_cuisiner",
    path: URL_PAGE_PANIER_REPAS_ENVIE_CUISINER,
  },
  {
    type: "panier_recette_perfectionner_cuisine",
    path: URL_PAGE_PANIER_RECETTE_PERFECTIONNER_CUISINE,
  },
  {
    type: "box_repas_manger_equilibre",
    path: URL_PAGE_BOX_REPAS_MANGER_EQUILIBRE,
  },
];
