import Api from "@middleware/api";
import { ICustomer } from "@middleware/types";
import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import { useClearAutentification } from "./useClearAutentification";

export const useCustomer = () => {
  const { user } = useAuth();
  const [customer, setCustomer] = useState<ICustomer>();
  const { resetAutentification } = useClearAutentification();

  useEffect(() => {
    const getCustomer = async () => {
      if (user) {
        const currentCustomer = await Api.customer.getCustomer(user.id);
        if (!currentCustomer) {
          await resetAutentification();

          return;
        }
        setCustomer(currentCustomer);
      }
    };

    void getCustomer();

    // fetch customer only after user change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return { customer, setCustomer };
};
