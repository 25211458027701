import cn from "clsx";
import { FC, useCallback, useEffect, useRef } from "react";
import { Cross } from "@components/icons";
import { EModalType } from "@middleware/types";
import FocusTrap from "@middleware/helpers/ui/focus-trap";
import { ID_MODAL } from "@middleware/constants";
import s from "./Modal.module.scss";

interface ModalProps {
  className?: string;
  children?: React.ReactNode;
  variant?: EModalType;
  onClose: () => void;
  onEnter?: () => void | null;
  showCloseBtn?: boolean;
}

export const Modal: FC<ModalProps> = ({
  children,
  className,
  onClose,
  variant,
  showCloseBtn = true,
}) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  const handleCloseOut = useCallback(
    (e: Event) => {
      const target = e.target as HTMLElement;
      if (target.id === ID_MODAL) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    window.addEventListener("click", handleCloseOut);
    document.body.classList.add("modal-open");

    return () => {
      window.removeEventListener("keydown", handleKey);
      window.removeEventListener("click", handleCloseOut);
      document.body.classList.remove("modal-open");
    };
  }, [handleKey, handleCloseOut]);

  return (
    <>
      <div
        className={cn(s.root, {
          [s.flot]: variant === EModalType.FLOTTANT,
        })}
      >
        {variant === EModalType.FLOTTANT && (
          <div className={s.flottant} id={ID_MODAL}></div>
        )}
        <div
          className={`${s.modal} ${className !== undefined && className}`}
          role="dialog"
          ref={ref}
        >
          {showCloseBtn && (
            <button
              onClick={onClose}
              aria-label="Close panel"
              className={s.close}
            >
              <Cross className="h-6 w-6" />
            </button>
          )}

          <FocusTrap children={children} />
        </div>
      </div>
    </>
  );
};
