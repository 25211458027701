import { PrismicRichText } from "@prismicio/react";
import { FC } from "react";
import { RichTextField } from "@prismicio/types";

interface Props {
  title: RichTextField;
}

export const TitlePage: FC<Props> = ({ title }) => {
  return (
    <>
      <div className="lk-separation" />
      <div className="title-page">
        <div className="lk-wrap">
          <div className="lk-wrap-content">
            <div className="lk-blc-title-s1 center">
              <div className="splayer">
                <PrismicRichText field={title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
