import Image from "next/image";
import { FC } from "react";

export interface Props {
  type?: string;
}

export const Logo: FC<Props> = ({ type }) => {
  return (
    <>
      {type === "footer" ? (
        <Image
          alt="Kitchendaily"
          src={"/static/images/footer/kitchendiet_logo_footer.svg"}
          width="115"
          height="115"
        />
      ) : (
        <Image
          alt="Kitchendaily"
          src={"/static/images/kitchendiet-daily-logo.svg"}
          width="122"
          height="42"
        />
      )}
    </>
  );
};
