import cn from "clsx";
import dynamic from "next/dynamic";
import { ProductModal } from "@components/catalog/product/ModalProduct/ProductModal";
import { Modal } from "@components/ui";
import { useUI } from "@middleware/hooks";
import { EModalType } from "@middleware/types";
import style from "./ModalUI.module.scss";

const AuthModal = dynamic(
  () =>
    import("@components/commun/Header/AuthModal/AuthModal").then(
      (mod) => mod.AuthModal
    ),
  {
    ssr: false,
    loading: () => null,
  }
);

const ModalView: React.FC<{ modalView: string; closeModal(): void }> = ({
  modalView,
  closeModal,
}) => {
  return (
    <Modal
      className={cn({
        [style.loginModal]: modalView === "SIGNUP_VIEW",
        [style.productModal]: modalView === "PRODUCT_VIEW",
      })}
      onClose={closeModal}
      variant={
        modalView === "PRODUCT_VIEW" ? EModalType.FLOTTANT : EModalType.FIXED
      }
    >
      {modalView === "SIGNUP_VIEW" && <AuthModal />}
      {modalView === "PRODUCT_VIEW" && <ProductModal />}
    </Modal>
  );
};

export const ModalUI: React.FC = () => {
  const { displayModal, closeModal, modalView } = useUI();

  return displayModal ? (
    <ModalView modalView={modalView} closeModal={closeModal} />
  ) : null;
};
