import { StylesConfig } from "react-select";

type IsMulti = false;
export const customStyles: StylesConfig<unknown, IsMulti> = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#f8efe0"
      : state.isFocused
      ? "#FBF4E9"
      : "#fff",
    color: "#C8B6A0",
    padding: "7px 10px",
    fontSize: "14px",
    borderBottom: state.isSelected ? "none" : "1px solid #C8B6A0",
    cursor: "pointer",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: "15px",
    padding: "0 5px",
    margin: 0,
    cursor: "pointer",
  }),
  container: (provided, state) => ({
    ...provided,
    zIndex: state.isFocused ? 20 : 1,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: "none",
    margin: 0,
    top: 40,
  }),
  menuList: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    top: 0,
    border: "1px solid #C8B6A0",
    borderRadius: 5,
  }),
  control: (provided) => ({
    ...provided,
    minHeight: "32px",
    boxShadow: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 8px",
    color: "#76bf72",
    zIndex: 2,
    cursor: "pointer",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: "black" };
  },
};
